class ThermometerService {
  constructor() {
    this.data = null;
    this.listeners = [];
  }

  handleSensorData(data) {
    //console.log('Dados recebidos no ThermometerService:', data);
    this.data = data;
    this.notifyListeners();
  }
  

  getChillerData(chillerName) {
    if (this.data && this.data[chillerName]) {
      return this.data[chillerName];
    }
    return null;
  }

  // Método para os componentes se inscreverem nas atualizações
  subscribe(callback) {
    this.listeners.push(callback);
  }

  // Método para os componentes cancelarem a inscrição
  unsubscribe(callback) {
    this.listeners = this.listeners.filter(listener => listener !== callback);
  }

  // Notificar todos os inscritos sobre a atualização dos dados
  notifyListeners() {
    console.log('Notificando os listeners...');
    this.listeners.forEach(callback => callback(this.data));
  }
  
}

export const thermometerService = new ThermometerService();


