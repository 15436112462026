import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './auth.css'; 
import authService from '../../services/auth/authService'; 

function Auth() {
  const [mode, setMode] = useState('login'); 
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (mode === 'login') {
      authService.login(email, password)
        .then(() => {
          window.location.reload();  // Recarregar a página após o login bem-sucedido para atualizar o App.js
        })
        .catch(error => console.error(error));
    } else if (mode === 'register') {
      if (password !== confirmPassword) {
        alert('Passwords do not match!');
        return;
      }
      authService.register(email, password)
        .then(() => {
          alert('Registration successful! Check your email for verification.');
          navigate('/login');
        })
        .catch(error => {
          console.error(error);
          alert('Error during registration: ' + error.message);
        });
    } else if (mode === 'reset') {
      authService.resetPassword(email)
        .then(() => alert('Check your email for a reset link!'))
        .catch(error => console.error(error));
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <div className="auth-header">
          <h3>{mode === 'login' ? 'Login' : mode === 'register' ? 'Create Account' : 'Reset Password'}</h3>
        </div>
        <div className="auth-body">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {mode !== 'reset' && (
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            )}
            {mode === 'register' && (
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          )}

          <button type="submit" disabled={mode === 'register' && password !== confirmPassword}>
            {mode === 'login' ? 'Login' : mode === 'register' ? 'Register' : 'Reset Password'}
          </button>

          </form>
        </div>
        <div className="auth-footer">
        {mode === 'login' && (
          <>
            <span className="auth-footer-forgot-password" onClick={() => setMode('reset')}>Forgot Password?</span>
            <span className="auth-footer-create-account" onClick={() => setMode('register')}>Create Account</span>
          </>
        )}
        {mode === 'register' && <span className="auth-footer-already-account" onClick={() => setMode('login')}>Already have an account? Login</span>}
        {mode === 'reset' && <span className="auth-footer-back-login" onClick={() => setMode('login')}>Back to Login</span>}
      </div>
      </div>
    </div>
  );
}

export default Auth;
