import React, { useState, useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';
import './GaugeChart.css'; // Importando o CSS específico

const GaugeChartComponent = ({ value, min = 0, max = 100, units = '°C' }) => {
  const [previousValue, setPreviousValue] = useState(value);

  useEffect(() => {
    if (previousValue !== value) {
      setPreviousValue(value);
    }
  }, [value, previousValue]);

  const percent = (previousValue - min) / (max - min);

  return (
    <div className="gauge-chart-wrapper">
      <GaugeChart
        id="gauge"
        nrOfLevels={20}
        percent={percent}
        textColor="#000000"
        formatTextValue={() => ``}
        style={{ width: '100%', height: '100%', maxWidth: '200px', maxHeight: '200px' }} // Limite o tamanho do Gauge
      />
    </div>
  );
};

export default GaugeChartComponent;
