import axios from 'axios';

// Atualizando o authService para utilizar o endpoint de produção usando variáveis de ambiente
const authService = {
  
  // Função de registro de usuário
  register(email, password) {
    // Usando a variável de ambiente REACT_APP_API_URL para o endpoint de produção
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, { email, password });
  },

  // Função de reset de senha
  resetPassword(email) {
    // Usando a variável de ambiente REACT_APP_API_URL para o endpoint de produção
    return axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, { email });
  },

  // Função de login usando async/await
  async login(email, password) {
    try {
      // Usando a variável de ambiente REACT_APP_API_URL para o endpoint de produção
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, { email, password });
      
      // Verifica se o token foi retornado
      if (response.data && response.data.access_token) {
        console.log('Token recebido:', response.data.access_token);
        localStorage.setItem('access_token', response.data.access_token); // Armazena o token no localStorage
      } else {
        console.error('Nenhum token JWT retornado pela API.');
      }

      return response.data;
    } catch (error) {
      console.error('Erro durante o login:', error);
      throw error;
    }
  },

  // Função para pegar o token do localStorage
  getToken() {
    return localStorage.getItem('access_token'); // Retorna o token armazenado no localStorage
  },

  // Função para decodificar o role do token
  getUserRoleFromToken(token) {
    if (!token) return null;
    try {
      const payload = JSON.parse(atob(token.split('.')[1])); // Decodifica o payload JWT
      return payload.role;  // Retorna o role do token
    } catch (error) {
      console.error("Erro ao decodificar o token:", error);
      return null;
    }
  },

  // Função para remover o token do localStorage (Logout)
  logout() {
    localStorage.removeItem('access_token');  // Remove o token quando o usuário sai
  }
};

export default authService;
