import React, { useEffect, useState } from 'react';
import GaugeChartComponent from '../charts/GaugeChart';
import './Thermometer.css';
import { thermometerService } from '../../services/thermometers/ThermometerService';

const Thermometer = ({ chillerName, tempMin, tempMax }) => {
  const [data, setData] = useState(null);

// console.log(`Renderizando dados para ${chillerName}:`, data);
useEffect(() => {
  const updateData = () => {
    const chillerData = thermometerService.getChillerData(chillerName);
    if (chillerData && JSON.stringify(chillerData) !== JSON.stringify(data)) {
      setData(chillerData);
      // console.log(`Estado atualizado para ${chillerName}:`, chillerData);
    }
  };
  
  

  // Inscrever-se nas atualizações do ThermometerService
  thermometerService.subscribe(updateData);

  // Carregar dados iniciais, caso já estejam disponíveis
  updateData();

  // Limpar a inscrição ao desmontar o componente
  return () => {
    thermometerService.unsubscribe(updateData);
  };
}, [chillerName, data]);

if (!data) {
  return <p>Carregando dados...</p>;
}

  return (
    <div className="thermometer-container">
      <h3>{chillerName}</h3>
      <div className="gauge-container">
        <div className="gauge-item">
          <h4>Return Temp</h4>
          <GaugeChartComponent value={parseFloat(data[`CH${chillerName.split(' ')[1]}_Return_Temp`]).toFixed(1)} min={tempMin} max={tempMax} units="°C" />
          <p className="temp-label">{parseFloat(data[`CH${chillerName.split(' ')[1]}_Return_Temp`]).toFixed(1)}°C</p>
        </div>
        <div className="gauge-item">
          <h4>Supply Temp</h4>
          <GaugeChartComponent value={parseFloat(data[`CH${chillerName.split(' ')[1]}_Supply_Temp`]).toFixed(1)} min={tempMin} max={tempMax} units="°C" />
          <p className="temp-label">{parseFloat(data[`CH${chillerName.split(' ')[1]}_Supply_Temp`]).toFixed(1)}°C</p>
        </div>
        <div className="gauge-item">
          <h4>External Temp</h4>
          <GaugeChartComponent value={parseFloat(data[`CH${chillerName.split(' ')[1]}_External_Temp`]).toFixed(1)} min={tempMin} max={tempMax} units="°C" />
          <p className="temp-label">{parseFloat(data[`CH${chillerName.split(' ')[1]}_External_Temp`]).toFixed(1)}°C</p>
        </div>
        <div className="gauge-item">
          <h4>SetPoint</h4>
          <GaugeChartComponent value={parseFloat(data[`CH${chillerName.split(' ')[1]}_Setpoint_CMD`]).toFixed(1)} min={tempMin} max={tempMax} units="°C" />
          <p className="temp-label">{parseFloat(data[`CH${chillerName.split(' ')[1]}_Setpoint_CMD`]).toFixed(1)}°C</p>
        </div>
      </div>
    </div>
  );
};

export default Thermometer;
