import React from 'react';

function FirePage() {
  return (
    <div>
      <h1>Bem-vindo à Página Fire</h1>
      <p>Conteúdo da página Fire aqui...</p>
    </div>
  );
}

export default FirePage;
