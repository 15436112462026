import React, { createContext, useContext, useEffect, useState, useCallback } from 'react';
import { thermometerService } from '../services/thermometers/ThermometerService';  // Importar o ThermometerService

const WebSocketContext = createContext(null);

export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);

  const connectWebSocket = useCallback(() => {
      if (socket) return;  // Evita múltiplas conexões

      const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
      const newSocket = new WebSocket(`${protocol}://${window.location.hostname}/ws/`);

      newSocket.onopen = () => {
          console.log('Conexão WebSocket estabelecida');
      };

      newSocket.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          //console.log('Mensagem recebida via WebSocket:', message);
          thermometerService.handleSensorData(message);
        } catch (error) {
          console.error('Falha ao interpretar mensagem:', error);
        }
      };

      newSocket.onerror = (error) => {
          console.error('Erro no WebSocket:', error);
      };

      newSocket.onclose = () => {
          console.log('Conexão WebSocket fechada');
          setSocket(null);  // Permitir reconexão se necessário
      };

      setSocket(newSocket);
  }, [socket]);

  useEffect(() => {
      connectWebSocket();
      return () => {
          if (socket) {
              socket.close();  // Fecha a conexão ao desmontar
          }
      };
  }, [connectWebSocket, socket]);

  return (
      <WebSocketContext.Provider value={{ socket }}>
          {children}
      </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);
