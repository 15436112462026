import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Thermometer from '../../components/sensors/Thermometer';
import './Home.css';

const Home = () => {
  const [isThermometerVisible, setIsThermometerVisible] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({ name: '', x: 0, y: 0 });

  const loadSVG = useCallback(async () => {
    try {
      const response = await axios.get('/production.svg');
      const svgData = response.data;
      const parser = new DOMParser();
      const svg = parser.parseFromString(svgData, 'image/svg+xml').querySelector('svg');

      if (!svg) {
        console.error('SVG not found or not parsed correctly');
        return;
      }

      svg.setAttribute('class', 'interactive-svg');
      svg.setAttribute('preserveAspectRatio', 'none');
      svg.setAttribute('viewBox', '0 0 7680 4320');

      const svgContainer = document.getElementById('svg-container');
      svgContainer.innerHTML = '';
      svgContainer.appendChild(svg);

      const paths = svg.querySelectorAll('path');
      paths.forEach(path => {
        path.addEventListener('mouseover', () => {
          path.classList.add('highlight-hover');
        });
        path.addEventListener('mouseout', () => {
          path.classList.remove('highlight-hover');
        });
        path.addEventListener('click', (event) => {
          const isSelected = path.classList.contains('selected');
          setIsThermometerVisible(!isSelected);
          paths.forEach(p => p.classList.remove('selected'));
          path.classList.toggle('selected', !isSelected);
        
          if (!isSelected) {
            const svgContainer = document.getElementById('svg-container');
            const svgRect = svgContainer.getBoundingClientRect();
            const xPercent = ((event.clientX - svgRect.left) / svgRect.width) * 100;
            const yPercent = ((event.clientY - svgRect.top) / svgRect.height) * 100;
            setSelectedLocation({ name: 'Prod Punch', x: xPercent, y: yPercent });
          } else {
            setSelectedLocation({ name: '', x: 0, y: 0 });
          }
        });
        
        
      });
    } catch (error) {
      console.error('Failed to load SVG:', error);
    }
  }, []);

  useEffect(() => {
    loadSVG();
  }, [loadSVG]);

  return (
    <div className="home-container">
      <div className="image-container">
        <img src="/production.png" alt="Production" className="background-image" />
        <div id="svg-container" className="svg-container"></div>

        {/* Nome da localização */}
        {selectedLocation.name && (
        <div
          className="location-name"
          style={{
            left: `${selectedLocation.x}%`,
            top: `${selectedLocation.y}%`
          }}
        >
          {selectedLocation.name}
        </div>
      )}


        {/* Termômetros dos chillers */}
        {isThermometerVisible && (
          <>
            <div className="thermometer-wrapper chiller1">
              <Thermometer chillerName="Chiller 1" tempMin={0} tempMax={50} />
            </div>
            <div className="thermometer-wrapper chiller2">
              <Thermometer chillerName="Chiller 2" tempMin={0} tempMax={50} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Home;
