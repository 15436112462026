import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Auth from './components/auth/Auth';
import Home from './pages/Home/Home';
import FirePage from './pages/Fire/FirePage'; 
import { WebSocketProvider } from './context/WebSocketContext';
import authService from './services/auth/authService';
import './App.css';  // Importa o arquivo de estilos

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);

  const handleLoginSuccess = () => {
    const token = authService.getToken();  // Pega o token do localStorage
    console.log("Token encontrado no localStorage:", token);  // Log para verificar se o token existe
    if (token) {
      const userRole = authService.getUserRoleFromToken(token);  // Decodifica o role do token
      setRole(userRole);
      if (userRole) {
        setIsAuthenticated(true);
      }
    }
  };

  useEffect(() => {
    handleLoginSuccess();  // Chama a função na montagem
  }, []);

  const permittedAutomationRoles = ["db-admin", "admin autom", "autom 1", "autom 2", "autom 3"];
  const permittedFireRoles = ["admin fire", "fire 1", "fire 2", "fire 3"];

  const handleLogout = () => {
    authService.logout();  // Remove o token do localStorage
    setIsAuthenticated(false);  // Atualiza o estado
    setRole(null);  // Limpa o role
    window.location.href = '/';  // Redireciona para a página de login
  };

  return (
    <Router>
      {isAuthenticated ? (
        <>
          {/* Botão de Logout estilizado */}
          <button className="logout-button" onClick={handleLogout}>Logout</button>

          {permittedAutomationRoles.includes(role) && (
            <WebSocketProvider>
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="*" element={<Navigate to="/home" />} />
              </Routes>
            </WebSocketProvider>
          )}
          {permittedFireRoles.includes(role) && (
            <Routes>
              <Route path="/fire" element={<FirePage />} />
              <Route path="*" element={<Navigate to="/fire" />} />
            </Routes>
          )}
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      )}
    </Router>
  );
}

export default App;
